@tailwind utilities;

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeInDown {
    from {
        transform: translateY(-2%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@layer utilities {
    .text-white {
        color: #fff;
    }
    .border-custom-danger {
        border-color: #ff0000 !important;
    }
    .bg-white {
        background-color: #fff;
    }
    .p-0-important {
        padding: 0px !important;
    }
    .min-hw-64 {
        min-width: 16rem;
        min-height: 16rem;
		@media screen and (max-width: 813px) {
			min-width: 12rem;
			min-height: 12rem;
		}	
    }
    .animate-fade-in {
        animation-name: fadeIn;
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        opacity: 0;
    }
    .animate-fade-in-down {
        animation-name: fadeInDown;
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        opacity: 0;
    }
    .music-box-gradient {
        background: linear-gradient(-45deg, #d676ef, #f67aa2, #cbe2ff, #e3faff);
        background-size: 400% 400%;
        box-shadow: 0 8px 44px 0px rgb(23 25 27 / 10%);

        &--animating {
            animation: gradient 6s ease infinite;
        }
        .music-box-gradient_controls {
            @apply hidden;
            @apply animate-fade-in;
        }
        &:hover {
            .music-box-gradient_controls {
                @apply flex;
            }
        }
    }
    .sidebar-dropdown {
        box-shadow: 0px 3px 20px rgba(23, 25, 27, 0.1);
    }
    .fill-light-important {
        fill: #fff !important;
        * {
            fill: #fff !important;
        }
    }
    .stroke-light-important {
        stroke: #fff !important;
        * {
            stroke: #fff !important;
        }
    }
    .stroke-grey-important {
        stroke: #8b94a1 !important;
        * {
            stroke: #8b94a1 !important;
        }
    }
    .fill-grey-important {
        fill: #8b94a1 !important;
        * {
            fill: #8b94a1 !important;
        }
    }
    .fill-dark-important {
        fill: #000 !important;
        * {
            fill: #000 !important;
        }
    }
    .stroke-dark-important {
        stroke: #000 !important;
        * {
            stroke: #000 !important;
        }
    }
	.force-top-0 {
		top: 0rem !important;
	}
    .filter-blur {
        -webkit-filter: blur(4px); /* Safari 6.0 - 9.0 */
        filter: blur(4px);
        &.sm {
            -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
            filter: blur(2px);
        }
    }
    .modal-shadow {
        box-shadow: 0 4px 48px -12px rgb(23 25 27 / 16%);
    }

    .animate-underline {
        &.animate-underline-active {
            &:before,
            &:after {
                width: 50%;
                opacity: 1;
            }
        }
        position: relative;
        transition: all 0.2s ease-in-out;
        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            width: 0px;
            height: 1px;
            margin: 0px 0 0;
            transition: all 0.2s ease-in-out;
            opacity: 0;
			@apply dark:bg-custom-darkmodeTextGray;
			@apply bg-black;
            //background-color: darken(black, 5%);
        }
        &:before {
            left: 25%;
        }
        &:after {
            right: 25%;
        }
        &:hover {
            &:before,
            &:after {
                width: 50%;
                opacity: 1;
            }
        }
    }
}
