@tailwind base;

@layer base {
    .input {
        @apply p-3;
        @apply cursor-pointer;
        @apply text-sm;
        @apply font-normal;

        @apply border;
        @apply rounded-sm;
        @apply border-custom-light;
        @apply hover:border-custom-light;

        @apply w-full;
        @apply focus-within:outline-none;
        @apply focus:outline-none;
        @apply focus:border-custom-black;
        @apply outline-none;

        @apply dark:bg-custom-black;
        @apply dark:hover:bg-custom-black;
        @apply dark:focus:bg-custom-black;
        @apply dark:focus-within:bg-custom-black;

        @apply dark:border-custom-darkmodeDividerGray;
        @apply dark:hover:border-custom-darkmodeDividerGray;

        @apply dark:text-white;
    }

    a {
        @apply outline-none;
        @apply focus-within:outline-none;
        @apply focus:outline-none;
    }

    button,
    .button {
        @apply p-3;
        @apply cursor-pointer;
        @apply text-sm;
        @apply font-semibold;

        @apply w-full;

        @apply rounded-sm;
        @apply outline-none;
        @apply focus-within:outline-none;

        @apply hover:opacity-80;
        @apply transition;
        @apply duration-200;

        @apply focus:outline-none;

		&:disabled {
			@apply cursor-default;
			@apply text-custom-placeholder;
			@apply dark:text-custom-darkmodeTextGray;
		}
    }
    .button-primary {
        @apply bg-custom-black;
        color: #fff;
    }

    .button-spotify {
        background-color: #62cd6b;
        color: #fff;
    }

    .input-dropdown {
        .input-dropdown-list {
            display: none;
        }
        &:focus-within {
            .input-dropdown-list {
                display: flex;
            }
        }
    }

    .button-invite {
        box-shadow: 0px 1px 1px rgba(23, 25, 27, 0.05);
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 20px;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            border-radius: 34px;
            &:before {
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            background-color: #15967f;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #15967f;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(12px);
            -ms-transform: translateX(12px);
            transform: translateX(12px);
        }
    }
}

@keyframes buttonLoadingSpinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
.is-loading {
    position: relative;
    justify-content: center;
    color: transparent !important;
    pointer-events: none;
    img {
        display: none;
    }
    --loading-color: #fff;

    &.is-loading-black {
        --loading-color: #000;
    }

    &:after {
        animation: buttonLoadingSpinner 500ms infinite linear;
        border: 2px solid var(--loading-color);
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: 1em;
        position: relative;
        width: 1em;
        position: absolute;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        position: absolute !important;
    }
}
