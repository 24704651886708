body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import "./styles/Variables";
@import "./styles/Base";
@import "./styles/Utilities";
@import "./styles/Form";

.emoji-picker-container {
    button {
        width: unset;
    }

    svg {
        display: unset;
    }
}
